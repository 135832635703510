import AutoText from "../components/AutoText";
import HomeBackground from "../components/HomeBackground";
import video from "../components/HomeBackground";

const Home = () => {
  return (
    <div>
      <HomeBackground />
      {/* <AutoText /> */}
    </div>
  );
};

export default Home;
